import styled from "styled-components";

export const StyledSelectInput = styled.div`
  position: relative;
  .selectedInput {
    background-color: transparent;
    border: transparent;
    display: flex;
    gap: 8px;
    cursor: pointer;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
    p {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 3.2rem;
      min-width: 8.2203rem;
    }
  }
  ul {
    width: 20rem;
    border: 1px solid var(--Grey-200);
    border-top: none;
    position: absolute;
    left: -1.7rem;
    top: 3.4rem;
    z-index: 99;
    /* background-color: red; */
    background-color: var(--Grey-100, #edeefa);
    list-style: none;
  }

  li {
    button {
      width: 100%;
      border: none;
      background-color: transparent;
      padding: 16px;
      display: flex;
      gap: 8px;
      cursor: pointer;
      align-items: center;
      &:hover {
        background-color: #e5e6f1;
      }
    }
    p {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 3.2rem;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .lupa {
    min-width: 1.7rem;
    min-height: 1.9rem;
  }
`;
