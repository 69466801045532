import arrowDown from "../assets/arrowDown.png";
import { useState, useEffect } from "react";
import FilterCategorySquare from "./FilterCategorySquare";
import { StyledFilterSubMenu } from "../styles/FilterSubMenu";
import { useSelector } from "react-redux";

function FilterSubMenu({
  nome,
  submenu,
  setPreInscricao,
  preInscricao,
  setInscricoesAbertas,
  inscricoesAbertas,
  setInscricoesEncerradas,
  inscricoesEncerradas,
  setSuperior,
  superior,
  setFundamental,
  fundamental,
  setMedio,
  medio,
  setMestrado,
  mestrado,
  setDoutorado,
  doutorado,
  cadastroReserva,
  setCadastroReserva,
  vagaUm,
  setVagaUm,
  vagaDoisDez,
  setVagaDoisDez,
  vagaDez,
  setVagaDez,
  vagaVinteCinco,
  setVagaVinteCinco,
  vagaCinquenta,
  setVagaCinquenta,
  vagaCem,
  setVagaCem,
  semRendimentos,
  setSemRendimentos,
  salario1,
  setSalario1,
  salario2,
  setSalario2,
  salario3,
  setSalario3,
  salario4,
  setSalario4,
  salario5,
  setSalario5,
  salario6,
  setSalario6,
  salario7,
  setSalario7,
  salario8,
  setSalario8,
  salario9,
  setSalario9,
  salario10,
  setSalario10,
  salario11,
  setSalario11,
  salario12,
  setSalario12,
  salario13,
  setSalario13,
  salario14,
  setSalario14,
  salario15,
  setSalario15,
  nacional,
  saoPaulo,
  rioDeJaneiro,
  distritoFederal,
  acre,
  alagoas,
  amapa,
  amazonas,
  bahia,
  ceara,
  espiritoSanto,
  goias,
  maranhao,
  matoGrosso,
  matoGrossoDoSul,
  minasGerais,
  para,
  paraiba,
  parana,
  pernambuco,
  piaui,
  rioGrandeDoNorte,
  rioGrandeDoSul,
  rondonia,
  roraima,
  santaCatarina,
  sergipe,
  tocantins,
  setNacional,
  setSaoPaulo,
  setRioDeJaneiro,
  setDistritoFederal,
  setAcre,
  setAlagoas,
  setAmapa,
  setAmazonas,
  setBahia,
  setCeara,
  setEspiritoSanto,
  setGoias,
  setMaranhao,
  setMatoGrosso,
  setMatoGrossoDoSul,
  setMinasGerais,
  setPara,
  setParaiba,
  setParana,
  setPernambuco,
  setPiaui,
  setRioGrandeDoNorte,
  setRioGrandeDoSul,
  setRondonia,
  setRoraima,
  setSantaCatarina,
  setSergipe,
  setTocantins,
  seta,
}) {
  const [setaDown, setSetaDown] = useState("rotate(0deg)");
  const [setaUp, setSetaUp] = useState("rotate(-90deg)");
  const [altura, setAltura] = useState(2.3 * submenu.length);
  const [alturaFechada, setAlturaFechada] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const [isPaid, setIsPaid] = useState(null);
  // * Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function requisicaoIsPayd(email) {
      const response = await fetch(`${URL}/user/users/?emails=${email}`, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      });
      const data = await response.json();
      return data.results[0].subscription_active;
    }

    if (user !== null) {
      requisicaoIsPayd(user.email).then((isPaid) => {
        setIsPaid(isPaid);
      });
    }
    if (user === null) {
      setIsPaid(false);
    }
  }, [user, Token, URL]);

  // useEffect(() => {

  // }, [isPaid]);

  function cliqueNaSeta() {
    if (seta === "down") {
      setSetaDown(
        setaDown === "rotate(0deg)" ? "rotate(-90deg)" : "rotate(0deg)"
      );
      setAltura(setaDown === "rotate(0deg)" ? 0 : 2.3 * submenu.length);
    } else {
      setSetaUp(setaUp === "rotate(0deg)" ? "rotate(-90deg)" : "rotate(0deg)");
      setAlturaFechada(setaUp === "rotate(0deg)" ? 0 : 2.3 * submenu.length);
    }
  }

  return (
    <StyledFilterSubMenu>
      <div className="FilterSubMenu">
        <h4>{nome}</h4>
        {nome === "Estado" ? (
          <img
            onClick={() => cliqueNaSeta()}
            className="seta"
            src={arrowDown}
            style={
              seta === "down" ? { transform: setaDown } : { transform: setaUp }
            }
            alt="seta"
          />
        ) : isPaid ? (
          <img
            onClick={() => cliqueNaSeta()}
            className="seta"
            src={arrowDown}
            style={
              seta === "down" ? { transform: setaDown } : { transform: setaUp }
            }
            alt="seta"
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_3112_3924)">
              <path
                d="M12.6667 5.616V4.66667C12.6667 3.42899 12.175 2.242 11.2998 1.36684C10.4247 0.491665 9.23768 0 8 0C6.76233 0 5.57534 0.491665 4.70017 1.36684C3.825 2.242 3.33334 3.42899 3.33334 4.66667V5.616C2.73958 5.87514 2.2342 6.30168 1.879 6.84347C1.52381 7.38527 1.33419 8.01882 1.33334 8.66667V12.6667C1.33439 13.5504 1.68592 14.3976 2.31082 15.0225C2.93571 15.6474 3.78294 15.9989 4.66667 16H11.3333C12.2171 15.9989 13.0643 15.6474 13.6892 15.0225C14.3141 14.3976 14.6656 13.5504 14.6667 12.6667V8.66667C14.6658 8.01882 14.4762 7.38527 14.121 6.84347C13.7658 6.30168 13.2604 5.87514 12.6667 5.616ZM4.66667 4.66667C4.66667 3.78261 5.01786 2.93477 5.64298 2.30964C6.2681 1.68452 7.11595 1.33333 8 1.33333C8.88406 1.33333 9.7319 1.68452 10.357 2.30964C10.9821 2.93477 11.3333 3.78261 11.3333 4.66667V5.33333H4.66667V4.66667ZM13.3333 12.6667C13.3333 13.1971 13.1226 13.7058 12.7476 14.0809C12.3725 14.456 11.8638 14.6667 11.3333 14.6667H4.66667C4.13624 14.6667 3.62753 14.456 3.25246 14.0809C2.87738 13.7058 2.66667 13.1971 2.66667 12.6667V8.66667C2.66667 8.13623 2.87738 7.62753 3.25246 7.25245C3.62753 6.87738 4.13624 6.66667 4.66667 6.66667H11.3333C11.8638 6.66667 12.3725 6.87738 12.7476 7.25245C13.1226 7.62753 13.3333 8.13623 13.3333 8.66667V12.6667Z"
                fill="#33394D"
              />
              <path
                d="M7.99967 9.33301C7.82286 9.33301 7.65329 9.40325 7.52827 9.52827C7.40325 9.65329 7.33301 9.82286 7.33301 9.99967V11.333C7.33301 11.5098 7.40325 11.6794 7.52827 11.8044C7.65329 11.9294 7.82286 11.9997 7.99967 11.9997C8.17649 11.9997 8.34605 11.9294 8.47108 11.8044C8.5961 11.6794 8.66634 11.5098 8.66634 11.333V9.99967C8.66634 9.82286 8.5961 9.65329 8.47108 9.52827C8.34605 9.40325 8.17649 9.33301 7.99967 9.33301Z"
                fill="#33394D"
              />
            </g>
            <defs>
              <clipPath id="clip0_3112_3924">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
      <div
        className="flexSquares"
        style={{
          maxHeight: `${seta === "down" ? altura : alturaFechada}rem`,
          overflow: "hidden",
          transition: "max-height 0.5s ease",
        }}
      >
        {submenu.map((categoria, index) => {
          // ESTADO
          return categoria === "Diversos" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={nacional}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "São Paulo" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={saoPaulo}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rio de Janeiro" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rioDeJaneiro}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Distrito Federal" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={distritoFederal}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Acre" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={acre}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Alagoas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={alagoas}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Amapá" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={amapa}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Amazonas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={amazonas}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Bahia" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={bahia}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Ceará" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={ceara}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Espírito Santo" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={espiritoSanto}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Goiás" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={goias}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Maranhão" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={maranhao}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Mato Grosso" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={matoGrosso}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Mato Grosso do Sul" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={matoGrossoDoSul}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Minas Gerais" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={minasGerais}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Pará" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={para}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Paraíba" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={paraiba}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Paraná" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={parana}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Pernambuco" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={pernambuco}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Piauí" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={piaui}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rio Grande do Norte" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rioGrandeDoNorte}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rio Grande do Sul" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rioGrandeDoSul}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rondônia" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rondonia}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Roraima" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={roraima}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Santa Catarina" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={santaCatarina}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Sergipe" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={sergipe}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Tocantins" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={tocantins}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : // ETAPAS
          categoria === "Edital publicado" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={preInscricao}
              setPreInscricao={setPreInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              liberado={isPaid}
            />
          ) : categoria === "Inscrições abertas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={inscricoesAbertas}
              setPreInscricao={setPreInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              liberado={isPaid}
            />
          ) : categoria === "Inscrições encerradas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={inscricoesEncerradas}
              setPreInscricao={setPreInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              liberado={isPaid}
            />
          ) : // EDUCAÇÃO
          categoria === "Superior" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={superior}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Fundamental" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={fundamental}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Médio" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={medio}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Mestrado" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={mestrado}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Doutorado" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={doutorado}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : // VAGAS
          categoria === "Cadastro de Reserva" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={cadastroReserva}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "1" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaUm}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "2 a 10" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaDoisDez}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "10 a 25" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaDez}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "25 a 50" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaVinteCinco}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "50 a 100" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaCinquenta}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "mais de 100" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaCem}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : // SALÁRIO
          categoria === "Sem rendimentos" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={semRendimentos}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "Até R$ 1.0000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario1}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 1.000 a R$ 1.500" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario2}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 1.500 a R$ 2.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario3}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 2.000 a R$ 2.500" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario4}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 2.500 a R$ 3.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario5}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 3.000 a R$ 4.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario6}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 4.000 a R$ 5.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario7}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 5.000 a R$ 7.500" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario8}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 7.500 a R$ 10.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario9}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 10.000 a R$ 15.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario10}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 15.000 a R$ 20.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario11}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 20.000 a R$ 30.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario12}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 30.000 a R$ 40.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario13}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "mais de R$ 40.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario14}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : null;
        })}
      </div>
    </StyledFilterSubMenu>
  );
}

export default FilterSubMenu;
