import styled from "styled-components";

export const StyledPayment = styled.main`
  background-color: #f2f3ff;
  min-height: calc(100vh - var(--FooterHeight) - var(--NavHeight));
  .PaymentMenu {
    margin: 9.6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6.4rem;
    .flexTittle {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: var(--Scale-2, 16px);
      align-items: center;
      h1 {
        max-width: 460px;
        text-align: center;
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 4.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 48px */
        letter-spacing: -0.96px;
      }
      p {
        color: var(--Grey-500, #606780);
        text-align: center;
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 17.6px */
      }
    }

    .flexCardPayment {
      display: flex;
      gap: 3.2rem;
    }
  }
`;
