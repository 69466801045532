import styled from "styled-components";

export const StyledResetConfirm = styled.main`
  background-color: var(--Primary-100, #e6e5ff);
  min-height: calc(100vh - var(--FooterHeight) - var(--NavHeight));
  .oldResetConfirm {
    display: flex;
    width: 42.4rem;
    padding: 4.8rem 3.2rem;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    border-radius: 1.6rem;
    background: var(--Primary-25, #f2f3ff);
    /* Drop shadow/sm */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    margin: 9.6rem auto;

    h1 {
      color: var(--Grey-700, #33394d);
      text-align: center;

      /* Title/Medium/Bold */
      font-family: "Work Sans";
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 24px */
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .form-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        label {
          color: var(--Grey-500, #606780);
          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16rem;
        }
        input {
          display: flex;
          height: 4.8rem;
          padding: 1rem 1.6rem;
          align-items: center;
          border-radius: 0.8rem;
          border: 1px solid var(--Grey-300, #a0a5bd);
          background: var(--Supporting-white, #fff);

          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          &::placeholder {
            color: var(--Grey-300, #a0a5bd);
          }
        }
      }
      button {
        display: flex;
        height: 5.6rem;
        padding: 2rem 3.2rem;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        border-radius: 0.6rem;
        border: none;
        background: var(--Primary-500, #1b2cc1);
        color: var(--Primary-050, #f0fdf4);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.8rem; /* 175% */
        margin-top: 2.4rem;
        cursor: pointer;
      }
    }

    svg {
      width: 9.6rem;
      height: 9.6rem;
      align-self: flex-start;
    }
    .flexTittleText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-4, 3.2rem);

      h1 {
        color: var(--Grey-700, #33394d);

        /* Title/Medium/Bold */
        font-family: "Work Sans";
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 24px */
      }
      p {
        color: var(--Grey-500, #606780);

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
  }
  .redirectWarning {
    max-width: 28rem;
    margin: 0 auto;
    text-align: center;
    p {
      color: var(--Grey-500, #606780);

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    a {
      text-decoration: none;
      color: var(--Primary-500, #1b2cc1);
      text-align: center;

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  .error {
    color: var(--Error-500, #d41e21);
    /* Text/Medium/Regular */
    font-family: "Work Sans";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  .form-control {
    outline: none;
  }
  .errorForm {
    border: var(--Scale-0, 1px) solid var(--Error-400, #de4c49) !important;
  }
`;
