import styled from "styled-components";

export const StyledWhiteNav = styled.nav`
  display: flex;
  padding: 14px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: var(--NavHeight);

  svg {
    cursor: pointer;
  }
`;
