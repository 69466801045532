import styled from "styled-components";

export const StyledUserAccount = styled.main`
  background-color: #f7f8ff;
  min-height: calc(100vh - var(--FooterHeight) - var(--NavHeight));
  section {
    display: flex;
    margin: 9.6rem auto;
    width: 112rem;
    .flexLeft {
      display: flex;
      flex-direction: column;
      width: 320px;
      align-items: flex-start;
      gap: var(--Scale-8, 64px);
      .flexBack {
        display: flex;
        align-items: center;
        gap: 1rem;
        align-self: start;
        svg {
          width: 22px;
          height: 22px;
        }
        a {
          color: var(--Primary-500, #1b2cc1);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .flexNav {
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }
  }
`;
