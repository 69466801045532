import Tag from "../UI/Tag";
import { StyledCardConcurso } from "../styles/CardConcurso";
import { Link } from "react-router-dom";
import {
  convertMoney,
  compararDatasNome,
  compararDatasClasse,
  conversaoData,
} from "../tools/functions";
import PopUp from "../UI/PopUp";
import { useState } from "react";

function CardConcurso({
  id,
  contest_number,
  name,
  location,
  wageRange,
  vacancy,
  registrationDeadline,
  imagemConcurso,
  setWhatCard,
  registration_opening,
}) {
  function definindoSetWhatCard() {
    setWhatCard({
      id: id,
    });
  }

  const [show, setShow] = useState(false);

  const estados = {
    PR: "Paraná",
    AL: "Alagoas",
    PA: "Pará",
    AP: "Amapá",
    SE: "Sergipe",
    RN: "Rio Grande do Norte",
    SC: "Santa Catarina",
    RO: "Rondônia",
    TO: "Tocantins",
    MA: "Maranhão",
    PI: "Piauí",
    RS: "Rio Grande do Sul",
    MG: "Minas Gerais",
    SP: "São Paulo",
    AC: "Acre",
    PB: "Paraíba",
    RJ: "Rio de Janeiro",
    MS: "Mato Grosso do Sul",
    RR: "Roraima",
    GO: "Goiás",
    CE: "Ceará",
    ES: "Espírito Santo",
    PE: "Pernambuco",
    BA: "Bahia",
    DF: "Distrito Federal",
    MT: "Mato Grosso",
    AM: "Amazonas",
    Diversos: "Nacional",
  };

  return (
    <StyledCardConcurso className="CardConcurso">
      <div className="iconAndTag">
        <div className="imagem">
          <img
            src={
              imagemConcurso
                ? imagemConcurso
                : "https://aloconcursos-staging-files20231022184301831600000001.s3.amazonaws.com/uploads/icons/4ba31314-e245-44b3-b92b-54ce4a8e2453.svg"
            }
            alt={name}
          />
        </div>
        <Tag
          nome={compararDatasNome(registration_opening, registrationDeadline)}
          classname={compararDatasClasse(
            registration_opening,
            registrationDeadline
          )}
        />
      </div>
      <div className="tittle">
        <h2>{name}</h2>
        <h4>
          {location === "Diversos" ? "Nacional" : estados[location.slice(-2)]}
        </h4>
        <h4>{`Edital ${contest_number}`}</h4>
      </div>
      <div className="divisaoInfo">
        <div className="InfoNumber">
          <div className="infoSubMenu">
            <p>salário até</p>
            <h2>{convertMoney(wageRange)}</h2>
          </div>
          <div className="infoSubMenu">
            <p>inscrições</p>
            <h2>
              {conversaoData(registration_opening)} a{" "}
              {conversaoData(registrationDeadline)}
            </h2>
          </div>
          <div className="infoSubMenu">
            <p>vagas</p>
            <h2>{vacancy === 0 ? "Cadastro de reserva" : vacancy}</h2>
          </div>
        </div>
      </div>
      <Link to={`/concurso/${id}`}>
        <button
          className="saberMais"
          onClick={() => {
            definindoSetWhatCard();
          }}
        >
          Saber mais
        </button>
      </Link>
    </StyledCardConcurso>
  );
}

export default CardConcurso;
