import styled from "styled-components";

export const StyledTerms = styled.main`
  min-height: calc(100vh - var(--FooterHeight) - var(--NavHeight));
  background-color: #f7f8ff;
  section {
    display: flex;
    margin: 9.6rem auto;
    width: 128rem;
    .flexLeft {
      display: flex;
      flex-direction: column;
      width: 320px;
      align-items: flex-start;
      gap: var(--Scale-8, 64px);
      .flexBack {
        display: flex;
        align-items: center;
        gap: 1rem;
        align-self: start;
        svg {
          width: 22px;
          height: 22px;
        }
        a {
          color: var(--Primary-500, #1b2cc1);
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: 0.16px;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .flexNav {
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }
    .flexRight {
      display: flex;
      width: 96rem;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-3, 24px);
      h1 {
        color: var(--Grey-700, #33394d);
        font-family: "Work Sans";
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.96px;
      }
      p {
        color: var(--Grey-600, #495066);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
      }
      span {
        color: var(--Grey-600, #495066);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.16px;
      }
      h5 {
        color: var(--Grey-600, #495066);
        font-family: "Work Sans";
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
      a {
        color: var(--Primary-500, #1b2cc1);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
        cursor: pointer;
        text-decoration: none;
      }
      ul {
        li {
          margin-left: 3.2rem;
          span {
            color: var(--Grey-600, #495066);
            font-family: "Work Sans";
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
        }
      }
    }
  }
`;
