import { StyledFilterCategorySquare } from "../styles/FilterCategorySquare";
import { Link } from "react-router-dom";
import Skeleton from "./Skeleton";
import { useEffect, useState } from "react";

function FilterCategorySquare({
  name,
  nome,
  checked,
  setPreInscricao,
  setInscricoesAbertas,
  setInscricoesEncerradas,
  setSuperior,
  setFundamental,
  setMedio,
  setMestrado,
  setDoutorado,
  setCadastroReserva,
  setVagaUm,
  setVagaDoisDez,
  setVagaDez,
  setVagaVinteCinco,
  setVagaCinquenta,
  setVagaCem,
  setSemRendimentos,
  setSalario1,
  setSalario2,
  setSalario3,
  setSalario4,
  setSalario5,
  setSalario6,
  setSalario7,
  setSalario8,
  setSalario9,
  setSalario10,
  setSalario11,
  setSalario12,
  setSalario13,
  setSalario14,
  setNacional,
  setSaoPaulo,
  setRioDeJaneiro,
  setDistritoFederal,
  setAcre,
  setAlagoas,
  setAmapa,
  setAmazonas,
  setBahia,
  setCeara,
  setEspiritoSanto,
  setGoias,
  setMaranhao,
  setMatoGrosso,
  setMatoGrossoDoSul,
  setMinasGerais,
  setPara,
  setParaiba,
  setParana,
  setPernambuco,
  setPiaui,
  setRioGrandeDoNorte,
  setRioGrandeDoSul,
  setRondonia,
  setRoraima,
  setSantaCatarina,
  setSergipe,
  setTocantins,
  liberado,
}) {
  const [loading, setLoading] = useState(false);
  const [skeleton, setSkeleton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSkeleton(true);
    }, 1000);
  }, []);
  function markedInfo(e) {
    // ESTADO
    if (e.target.id === "Diversos") {
      if (e.target.checked) {
        setNacional(true);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setNacional(false);
      }
    }
    if (e.target.id === "São Paulo") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(true);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setSaoPaulo(false);
      }
    }
    if (e.target.id === "Rio de Janeiro") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(true);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setRioDeJaneiro(false);
      }
    }
    if (e.target.id === "Distrito Federal") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(true);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setDistritoFederal(false);
      }
    }
    if (e.target.id === "Acre") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(true);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setAcre(false);
      }
    }
    if (e.target.id === "Alagoas") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(true);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setAlagoas(false);
      }
    }
    if (e.target.id === "Amapá") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(true);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setAmapa(false);
      }
    }
    if (e.target.id === "Amazonas") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(true);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setAmazonas(false);
      }
    }
    if (e.target.id === "Bahia") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(true);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setBahia(false);
      }
    }
    if (e.target.id === "Ceará") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(true);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setCeara(false);
      }
    }
    if (e.target.id === "Espírito Santo") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(true);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setEspiritoSanto(false);
      }
    }
    if (e.target.id === "Goiás") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(true);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setGoias(false);
      }
    }
    if (e.target.id === "Maranhão") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(true);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setMaranhao(false);
      }
    }
    if (e.target.id === "Mato Grosso") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(true);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setMatoGrosso(false);
      }
    }
    if (e.target.id === "Mato Grosso do Sul") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(true);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setMatoGrossoDoSul(false);
      }
    }
    if (e.target.id === "Minas Gerais") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(true);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setMinasGerais(false);
      }
    }
    if (e.target.id === "Pará") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(true);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setPara(false);
      }
    }
    if (e.target.id === "Paraíba") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(true);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setParaiba(false);
      }
    }
    if (e.target.id === "Paraná") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(true);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setParana(false);
      }
    }
    if (e.target.id === "Pernambuco") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(true);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setPernambuco(false);
      }
    }
    if (e.target.id === "Piauí") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(true);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setPiaui(false);
      }
    }
    if (e.target.id === "Rio Grande do Norte") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(true);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setRioGrandeDoNorte(false);
      }
    }
    if (e.target.id === "Rio Grande do Sul") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(true);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setRioGrandeDoSul(false);
      }
    }
    if (e.target.id === "Rondônia") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(true);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setRondonia(false);
      }
    }
    if (e.target.id === "Roraima") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(true);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(false);
      } else {
        setRoraima(false);
      }
    }
    if (e.target.id === "Santa Catarina") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(true);
        setSergipe(false);
        setTocantins(false);
      } else {
        setSantaCatarina(false);
      }
    }
    if (e.target.id === "Sergipe") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(true);
        setTocantins(false);
      } else {
        setSergipe(false);
      }
    }
    if (e.target.id === "Tocantins") {
      if (e.target.checked) {
        setNacional(false);
        setSaoPaulo(false);
        setRioDeJaneiro(false);
        setDistritoFederal(false);
        setAcre(false);
        setAlagoas(false);
        setAmapa(false);
        setAmazonas(false);
        setBahia(false);
        setCeara(false);
        setEspiritoSanto(false);
        setGoias(false);
        setMaranhao(false);
        setMatoGrosso(false);
        setMatoGrossoDoSul(false);
        setMinasGerais(false);
        setPara(false);
        setParaiba(false);
        setParana(false);
        setPernambuco(false);
        setPiaui(false);
        setRioGrandeDoNorte(false);
        setRioGrandeDoSul(false);
        setRondonia(false);
        setRoraima(false);
        setSantaCatarina(false);
        setSergipe(false);
        setTocantins(true);
      } else {
        setTocantins(false);
      }
    }

    // ETAPAS
    if (e.target.id === "Edital publicado") {
      if (e.target.checked) {
        setPreInscricao(true);
        setInscricoesAbertas(false);
        setInscricoesEncerradas(false);
      } else {
        setPreInscricao(false);
      }
    }
    if (e.target.id === "Inscrições abertas") {
      if (e.target.checked) {
        setPreInscricao(false);
        setInscricoesAbertas(true);
        setInscricoesEncerradas(false);
      } else {
        setInscricoesAbertas(false);
      }
    }
    if (e.target.id === "Inscrições encerradas") {
      if (e.target.checked) {
        setPreInscricao(false);
        setInscricoesAbertas(false);
        setInscricoesEncerradas(true);
      } else {
        setInscricoesEncerradas(false);
      }
    }

    // EDUCACIONAL
    if (e.target.id === "Superior") {
      if (e.target.checked) {
        setSuperior(true);
        setFundamental(false);
        setMedio(false);
        setMestrado(false);
        setDoutorado(false);
      } else {
        setSuperior(false);
      }
    }
    if (e.target.id === "Fundamental") {
      if (e.target.checked) {
        setSuperior(false);
        setFundamental(true);
        setMedio(false);
        setMestrado(false);
        setDoutorado(false);
      } else {
        setFundamental(false);
      }
    }
    if (e.target.id === "Médio") {
      if (e.target.checked) {
        setSuperior(false);
        setFundamental(false);
        setMedio(true);
        setMestrado(false);
        setDoutorado(false);
      } else {
        setMedio(false);
      }
    }
    if (e.target.id === "Mestrado") {
      if (e.target.checked) {
        setSuperior(false);
        setFundamental(false);
        setMedio(false);
        setMestrado(true);
        setDoutorado(false);
      } else {
        setMestrado(false);
      }
    }
    if (e.target.id === "Doutorado") {
      if (e.target.checked) {
        setSuperior(false);
        setFundamental(false);
        setMedio(false);
        setMestrado(false);
        setDoutorado(true);
      } else {
        setDoutorado(false);
      }
    }

    // VAGAS
    if (e.target.id === "Cadastro de Reserva") {
      if (e.target.checked) {
        setCadastroReserva(true);
        setVagaUm(false);
        setVagaDoisDez(false);
        setVagaDez(false);
        setVagaVinteCinco(false);
        setVagaCinquenta(false);
        setVagaCem(false);
      } else {
        setCadastroReserva(false);
      }
    }
    if (e.target.id === "1") {
      if (e.target.checked) {
        setCadastroReserva(false);
        setVagaUm(true);
        setVagaDoisDez(false);
        setVagaDez(false);
        setVagaVinteCinco(false);
        setVagaCinquenta(false);
        setVagaCem(false);
      } else {
        setVagaUm(false);
      }
    }
    if (e.target.id === "2 a 10") {
      if (e.target.checked) {
        setCadastroReserva(false);
        setVagaUm(false);
        setVagaDoisDez(true);
        setVagaDez(false);
        setVagaVinteCinco(false);
        setVagaCinquenta(false);
        setVagaCem(false);
      } else {
        setVagaDoisDez(false);
      }
    }
    if (e.target.id === "10 a 25") {
      if (e.target.checked) {
        setCadastroReserva(false);
        setVagaUm(false);
        setVagaDoisDez(false);
        setVagaDez(true);
        setVagaVinteCinco(false);
        setVagaCinquenta(false);
        setVagaCem(false);
      } else {
        setVagaDez(false);
      }
    }
    if (e.target.id === "25 a 50") {
      if (e.target.checked) {
        setCadastroReserva(false);
        setVagaUm(false);
        setVagaDoisDez(false);
        setVagaDez(false);
        setVagaVinteCinco(true);
        setVagaCinquenta(false);
        setVagaCem(false);
      } else {
        setVagaVinteCinco(false);
      }
    }
    if (e.target.id === "50 a 100") {
      if (e.target.checked) {
        setCadastroReserva(false);
        setVagaUm(false);
        setVagaDoisDez(false);
        setVagaDez(false);
        setVagaVinteCinco(false);
        setVagaCinquenta(true);
        setVagaCem(false);
      } else {
        setVagaCinquenta(false);
      }
    }
    if (e.target.id === "mais de 100") {
      if (e.target.checked) {
        setCadastroReserva(false);
        setVagaUm(false);
        setVagaDoisDez(false);
        setVagaDez(false);
        setVagaVinteCinco(false);
        setVagaCinquenta(false);
        setVagaCem(true);
      } else {
        setVagaCem(false);
      }
    }

    // SALÁRIO
    if (e.target.id === "Sem rendimentos") {
      if (e.target.checked) {
        setSemRendimentos(true);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSemRendimentos(false);
      }
    }
    if (e.target.id === "Até R$ 1.0000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(true);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario1(false);
      }
    }
    if (e.target.id === "R$ 1.000 a R$ 1.500") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(true);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario2(false);
      }
    }
    if (e.target.id === "R$ 1.500 a R$ 2.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(true);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario3(false);
      }
    }
    if (e.target.id === "R$ 2.000 a R$ 2.500") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(true);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario4(false);
      }
    }
    if (e.target.id === "R$ 2.500 a R$ 3.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(true);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario5(false);
      }
    }
    if (e.target.id === "R$ 3.000 a R$ 4.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(true);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario6(false);
      }
    }
    if (e.target.id === "R$ 4.000 a R$ 5.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(true);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario7(false);
      }
    }
    if (e.target.id === "R$ 5.000 a R$ 7.500") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(true);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario8(false);
      }
    }
    if (e.target.id === "R$ 7.500 a R$ 10.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(true);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario9(false);
      }
    }
    if (e.target.id === "R$ 10.000 a R$ 15.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(true);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario10(false);
      }
    }
    if (e.target.id === "R$ 15.000 a R$ 20.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(true);
        setSalario12(false);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario11(false);
      }
    }
    if (e.target.id === "R$ 20.000 a R$ 30.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(true);
        setSalario13(false);
        setSalario14(false);
      } else {
        setSalario12(false);
      }
    }
    if (e.target.id === "R$ 30.000 a R$ 40.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(true);
        setSalario14(false);
      } else {
        setSalario13(false);
      }
    }
    if (e.target.id === "mais de R$ 40.000") {
      if (e.target.checked) {
        setSemRendimentos(false);
        setSalario1(false);
        setSalario2(false);
        setSalario3(false);
        setSalario4(false);
        setSalario5(false);
        setSalario6(false);
        setSalario7(false);
        setSalario8(false);
        setSalario9(false);
        setSalario10(false);
        setSalario11(false);
        setSalario12(false);
        setSalario13(false);
        setSalario14(true);
      } else {
        setSalario14(false);
      }
    }
  }

  useEffect(() => {
    if (liberado !== null) {
      setLoading(true);
    }
  }, [liberado]);

  return (
    <StyledFilterCategorySquare className="square">
      {loading ? (
        liberado ? (
          <>
            <input
              onChange={(e) => {
                markedInfo(e);
              }}
              id={nome}
              name={name}
              type="checkbox"
              checked={checked}
            />
            <label htmlFor={nome}>{nome}</label>
          </>
        ) : (
          <>
            <Link to="/planos">
              <input type="checkbox" disabled />
            </Link>
            <label htmlFor={nome}>{nome}</label>
          </>
        )
      ) : skeleton ? (
        <Skeleton width={"23.2rem"} height={"1.5rem"} />
      ) : null}
    </StyledFilterCategorySquare>
  );
}

export default FilterCategorySquare;
