export function conversaoDataMes(data) {
  const dataAlvo = new Date(data);
  const dia = dataAlvo.getUTCDate();

  // Array com os nomes dos meses
  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const mes = meses[dataAlvo.getUTCMonth()]; // Obtém o nome do mês pelo índice
  const ano = dataAlvo.getUTCFullYear(); // Usa o ano completo

  // Formata a data no formato "dd de mês por extenso de aa"
  const dataFormatada = `${dia
    .toString()
    .padStart(2, "0")} de ${mes} de ${ano}`;

  return dataFormatada;
}

export function convertMoney(money) {
  let moneyConverted = parseFloat(money);
  return moneyConverted.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

export function convertKMoney(money) {
  let moneyConverted = money.toString().slice(0, -6) + "K+";
  return moneyConverted;
}

export function conversaoData(data) {
  const dataAlvo = new Date(data);
  const dia = dataAlvo.getUTCDate();
  const mes = dataAlvo.getUTCMonth() + 1;
  // const ano = dataAlvo.getUTCFullYear();
  // const dataFormatada = `${dia.toString().padStart(2, '0')}-${mes.toString().padStart(2, '0')}-${ano}`
  const ano = dataAlvo.getUTCFullYear() % 100;
  const dataFormatada = `${dia.toString().padStart(2, "0")}/${mes
    .toString()
    .padStart(2, "0")}/${ano.toString().padStart(2, "0")}`;
  return dataFormatada;
}

export function compararDatasClasse(inscricoesAbertas, inscricoesEncerradas) {
  const dataInscricao = new Date(inscricoesAbertas);
  const dataDeHoje = new Date();
  const dataEncerrada = new Date(inscricoesEncerradas);

  if (dataInscricao < dataDeHoje && dataEncerrada > dataDeHoje) {
    return "inscricoesAbertas";
  } else if (dataEncerrada < dataDeHoje) {
    return "inscricoesEncerradas";
  } else if (dataInscricao > dataDeHoje) {
    return "editalPublicado";
  }
}

export function compararDatasNome(inscricoesAbertas, inscricoesEncerradas) {
  const dataInscricao = new Date(inscricoesAbertas);
  const dataDeHoje = new Date();
  const dataEncerrada = new Date(inscricoesEncerradas);

  if (dataInscricao < dataDeHoje && dataEncerrada > dataDeHoje) {
    return "inscrições abertas";
  } else if (dataEncerrada < dataDeHoje) {
    return "inscrições encerradas";
  } else if (dataInscricao > dataDeHoje) {
    return "edital publicado";
  }
}

export function conversaoDia(data) {
  const dataAlvo = new Date(data);
  const dia = dataAlvo.getUTCDate();
  const mes = dataAlvo.getUTCMonth() + 1;
  // const dataFormatada = `${dia.toString().padStart(2, '0')}-${mes.toString().padStart(2, '0')}`
  const dataFormatada = `${dia.toString().padStart(2, "0")}/${mes
    .toString()
    .padStart(2, "0")}`;
  return dataFormatada;
}

export function conversaoSalario(salarioAlvo) {
  const salario = salarioAlvo.slice(0, -6);
  return salario;
}

export function wageConference(wage) {
  const keys = Object.keys(wage);
  if (keys.length > 1) {
    return `${convertMoney(wage[keys[0]])} a ${convertMoney(wage[keys[1]])}`;
  } else {
    return `${convertMoney(wage[keys[0]])}`;
  }
}

export function everyNull(lista) {
  let result = lista.every((item) => item === null);

  return result;
}

export function convertDate(date) {
  const newDate = new Date(date);

  const day = String(newDate.getDate() + 1).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const year = newDate.getFullYear();

  let formatedYear = year.toString().split("");
  formatedYear = formatedYear[2] + formatedYear[3];

  return `${day}/${month}/${formatedYear}`;
}
