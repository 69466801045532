import styled from "styled-components";

export const StyledFooterExpanded = styled.footer`
  background-color: var(--Grey-900);
  padding-top: 4.8rem;
  .footerContent {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 1440px;
    /* height: 250px; */
    .firstContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-4, 32px);
      .flexLogosvg {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        img {
          width: 74.297px;
          height: 62.198px;
        }
        svg {
          position: relative;
          top: -0.8rem;
          width: 170.808px;
          height: 25.676px;
        }
      }

      p {
        color: var(--Grey-100, #edeefa);
        width: 174px;
        font-family: "Work Sans";
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
        letter-spacing: 0.18px;
      }
    }
    .tripleFlex {
      display: flex;
      align-items: flex-start;
      gap: 64px;
      margin-bottom: 4.8rem;
      .menuFlex {
        width: 288px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Scale-2, 16px);
        span {
          color: var(--Grey-50, #f7f8ff);

          /* Text/Extra Large/Bold */
          font-family: "Work Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 25.2px */
        }
        a {
          text-decoration: none;
          color: var(--Primary-200, #aaaaf0);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          &:visited {
            color: var(--Primary-200, #aaaaf0);
            text-decoration: none;
          }
        }
      }
      .termos {
        width: 288px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: var(--Grey-50, #f7f8ff);
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          a {
            text-decoration: none;
            color: var(--Primary-200, #aaaaf0);

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: var(--Primary-200, #aaaaf0);
              text-decoration: none;
            }
          }
        }
      }
      .contato {
        width: 288px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4.8rem;
        .row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Scale-2, 16px);
          span {
            color: var(--Grey-50, #f7f8ff);
            /* Text/Extra Large/Bold */
            font-family: "Work Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
          }
          .email {
            text-decoration: none;
            color: var(--Primary-200, #aaaaf0);

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.16px;
            &:visited {
              color: var(--Primary-200, #aaaaf0);
              text-decoration: none;
            }
          }
          .flexSocial {
            display: flex;
            align-items: center;
            gap: var(--Scale-2, 16px);
            div {
              display: flex;
              padding: var(--Radius-md, 8px);
              align-items: center;
              border-radius: var(--Radius-md, 8px);
              border: 1px solid var(--Primary-200, #aaaaf0);
            }
          }
        }
      }
    }
  }
  .final {
    border-top: 1px solid #495066;
    display: flex;
    padding: var(--Scale-3, 24px) var(--Radius-md, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Radius-md, 8px);

    p {
      color: var(--Grey-300, #a0a5bd);
      width: 1440px;
      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
    }
  }
`;
