import React from "react";
import { StyledSelectInput } from "../styles/SelectInput";
import { useState } from "react";

function SelectInput({ defaultValue = "Cargos", setSelected }) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [ulVisible, setUlVisible] = useState(false);

  function cargos() {
    setSelectedOption("Cargos");
    setSelected("Cargos");
    setUlVisible(false);
  }
  function concursos() {
    setSelectedOption("Concursos");
    setSelected("Concursos");
    setUlVisible(false);
  }

  return (
    <StyledSelectInput>
      <button
        type="button"
        className="selectedInput"
        onClick={() => setUlVisible((prev) => !prev)}
      >
        {selectedOption === "Cargos" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="none"
          >
            <path
              d="M394.667 64H380.971C376.255 45.7356 365.62 29.5484 350.729 17.969C335.838 6.38965 317.53 0.0706933 298.667 0L213.333 0C194.47 0.0706933 176.162 6.38965 161.271 17.969C146.38 29.5484 135.745 45.7356 131.029 64H117.333C86.225 64.0339 56.4005 76.4066 34.4036 98.4036C12.4066 120.4 0.033881 150.225 0 181.333L0 394.667C0.033881 425.775 12.4066 455.6 34.4036 477.596C56.4005 499.593 86.225 511.966 117.333 512H394.667C425.773 511.961 455.595 499.586 477.59 477.59C499.586 455.595 511.961 425.773 512 394.667V181.333C511.961 150.227 499.586 120.405 477.59 98.4098C455.595 76.4141 425.773 64.0395 394.667 64ZM117.333 128H394.667C408.812 128 422.377 133.619 432.379 143.621C442.381 153.623 448 167.188 448 181.333V234.667H64V181.333C64 167.188 69.619 153.623 79.621 143.621C89.6229 133.619 103.188 128 117.333 128ZM394.667 448H117.333C103.188 448 89.6229 442.381 79.621 432.379C69.619 422.377 64 408.812 64 394.667V298.667H213.333C213.333 309.983 217.829 320.835 225.83 328.837C233.832 336.838 244.684 341.333 256 341.333C267.316 341.333 278.168 336.838 286.17 328.837C294.171 320.835 298.667 309.983 298.667 298.667H448V394.667C448 408.812 442.381 422.377 432.379 432.379C422.377 442.381 408.812 448 394.667 448Z"
              fill="#33394D"
            />
          </svg>
        ) : (
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="40 0 430 512"
            style={{ enableBackground: "new 0 0 512 512" }}
            xmlSpace="preserve"
          >
            <g>
              <g>
                <g>
                  <path d="M459.36,100.64l-96-96C360.341,1.645,356.253-0.024,352,0H96c-26.51,0-48,21.49-48,48v416c0,26.51,21.49,48,48,48h320c26.51,0,48-21.49,48-48V112C464.025,107.747,462.355,103.66,459.36,100.64z M432,464c0,8.837-7.163,16-16,16H96c-8.837,0-16-7.163-16-16V48c0-8.837,7.163-16,16-16h240v64c0,17.673,14.327,32,32,32h64V464z" />
                  <rect x="112" y="320" width="288" height="32" />
                  <rect x="112" y="400" width="288" height="32" />
                  <path d="M112,192v64c0,8.837,7.163,16,16,16h256c8.837,0,16-7.163,16-16v-64c0-8.837-7.163-16-16-16H128C119.163,176,112,183.164,112,192z M144,208h224v32H144V208z" />
                </g>
              </g>
            </g>
          </svg>
        )}
        <p>{selectedOption}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M5.23687 6H12.6902C12.8221 6.00055 12.9508 6.04019 13.0601 6.1139C13.1694 6.18761 13.2544 6.29208 13.3044 6.4141C13.3543 6.53612 13.367 6.67021 13.3407 6.79942C13.3145 6.92863 13.2505 7.04715 13.1569 7.14L9.43687 10.86C9.37489 10.9225 9.30116 10.9721 9.21992 11.0059C9.13868 11.0398 9.05154 11.0572 8.96354 11.0572C8.87553 11.0572 8.78839 11.0398 8.70715 11.0059C8.62591 10.9721 8.55218 10.9225 8.4902 10.86L4.7702 7.14C4.67659 7.04715 4.61261 6.92863 4.58635 6.79942C4.56009 6.67021 4.57274 6.53612 4.62269 6.4141C4.67264 6.29208 4.75765 6.18761 4.86698 6.1139C4.9763 6.04019 5.10502 6.00055 5.23687 6Z"
            fill="#33394D"
          />
        </svg>
      </button>
      {ulVisible ? (
        <ul>
          {selectedOption === "Cargos" ? (
            <li onClick={() => concursos()}>
              <button>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="40 0 430 512"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path d="M459.36,100.64l-96-96C360.341,1.645,356.253-0.024,352,0H96c-26.51,0-48,21.49-48,48v416c0,26.51,21.49,48,48,48h320c26.51,0,48-21.49,48-48V112C464.025,107.747,462.355,103.66,459.36,100.64z M432,464c0,8.837-7.163,16-16,16H96c-8.837,0-16-7.163-16-16V48c0-8.837,7.163-16,16-16h240v64c0,17.673,14.327,32,32,32h64V464z" />
                        <rect x="112" y="320" width="288" height="32" />
                        <rect x="112" y="400" width="288" height="32" />
                        <path d="M112,192v64c0,8.837,7.163,16,16,16h256c8.837,0,16-7.163,16-16v-64c0-8.837-7.163-16-16-16H128C119.163,176,112,183.164,112,192z M144,208h224v32H144V208z" />
                      </g>
                    </g>
                  </g>
                </svg>

                <p>Concursos</p>
              </button>
            </li>
          ) : (
            <li onClick={() => cargos()}>
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                >
                  <path
                    d="M394.667 64H380.971C376.255 45.7356 365.62 29.5484 350.729 17.969C335.838 6.38965 317.53 0.0706933 298.667 0L213.333 0C194.47 0.0706933 176.162 6.38965 161.271 17.969C146.38 29.5484 135.745 45.7356 131.029 64H117.333C86.225 64.0339 56.4005 76.4066 34.4036 98.4036C12.4066 120.4 0.033881 150.225 0 181.333L0 394.667C0.033881 425.775 12.4066 455.6 34.4036 477.596C56.4005 499.593 86.225 511.966 117.333 512H394.667C425.773 511.961 455.595 499.586 477.59 477.59C499.586 455.595 511.961 425.773 512 394.667V181.333C511.961 150.227 499.586 120.405 477.59 98.4098C455.595 76.4141 425.773 64.0395 394.667 64ZM117.333 128H394.667C408.812 128 422.377 133.619 432.379 143.621C442.381 153.623 448 167.188 448 181.333V234.667H64V181.333C64 167.188 69.619 153.623 79.621 143.621C89.6229 133.619 103.188 128 117.333 128ZM394.667 448H117.333C103.188 448 89.6229 442.381 79.621 432.379C69.619 422.377 64 408.812 64 394.667V298.667H213.333C213.333 309.983 217.829 320.835 225.83 328.837C233.832 336.838 244.684 341.333 256 341.333C267.316 341.333 278.168 336.838 286.17 328.837C294.171 320.835 298.667 309.983 298.667 298.667H448V394.667C448 408.812 442.381 422.377 432.379 432.379C422.377 442.381 408.812 448 394.667 448Z"
                    fill="#33394D"
                  />
                </svg>
                <p>Cargos</p>
              </button>
            </li>
          )}
        </ul>
      ) : null}
    </StyledSelectInput>
  );
}

export default SelectInput;
