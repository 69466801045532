import styled from "styled-components";

export const StyledNotFound = styled.main`
  min-height: calc(100vh - var(--FooterHeight) - var(--NavHeight));
  section {
    margin-top: 12.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.8rem;
    .tittleSubtittle {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      h2 {
        color: #5069a3;
        font-family: "Work Sans";
        font-size: 6.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      h1 {
        color: #5069a3;
        text-shadow: -4px 4px 0px #393b40;
        font-family: "Work Sans";
        font-size: 36rem;
        font-style: normal;
        font-weight: 400;
        line-height: 80%; /* 288px */
        letter-spacing: -18px;
      }
    }
    .tittleText {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.6rem;

      h3 {
        color: var(--Grey-600, #495066);
        font-family: "Work Sans";
        font-size: 3.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }
      p {
        color: var(--Grey-600, #495066);
        font-family: "Work Sans";
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.18px;
        text-align: center;
      }
    }
    button {
      display: flex;
      height: 5.6rem;
      padding: 2rem 3.2rem;
      justify-content: center;
      align-items: center;
      gap: var(--Radius-md, 8px);
      border-radius: 6px;
      background: var(--Primary-500, #1b2cc1);
      color: var(--Primary-050, #f0fdf4);
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      border: none;
      cursor: pointer;
    }
  }
`;
