import styled from "styled-components";

export const StyledSucessPayment = styled.main`
  position: relative;
  background-color: #f7f8ff;
  min-height: calc(100vh - var(--FooterHeight) - var(--NavHeight));
  .popUpSuccess {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    width: 480px;
    padding: var(--Scale-2, 16px) 32px 48px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    border-radius: var(--Radius-md, 8px);
    background: var(--Supporting-white, #fff);

    /* Drop shadow/sm */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    .xIcon {
      align-self: end;
      cursor: pointer;
    }
    .diamondAndText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-2, 16px);

      .textAndTittle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Radius-md, 8px);

        h1 {
          color: var(--Grey-700, #33394d);

          /* Text/Extra Large/Bold */
          font-family: "Work Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 25.2px */
        }
        p {
          color: var(--Grey-500, #606780);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          span {
            color: var(--Grey-500, #606780);

            /* Text/Large/Regular */
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.16px;
          }
        }
      }
    }
    button {
      cursor: pointer;
      display: flex;
      height: 48px;
      padding: var(--Scale-2, 16px) 32px;
      justify-content: center;
      align-items: center;
      gap: var(--Scale-2, 16px);
      border-radius: var(--Radius-md, 8px);
      background: var(--Primary-500, #1b2cc1);
      color: var(--Supporting-white, #fff);
      text-align: center;
      border: none;
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
    }
  }
`;
