import styled from "styled-components";

export const StyledHomepageStatistics = styled.div`
  display: flex;
  padding: var(--Scale-2, 16px) 48px;
  flex-direction: column;
  place-items: center;
  gap: var(--Radius-md, 8px);
  border-radius: var(--Scale-2, 16px);
  background: #5069a3;
  width: 336px;
  height: 197px;

  .flexCardStatistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Radius-md, 8px);

    .flexTittleText {
      display: flex;
      gap: 0.8rem;
      p {
        align-self: flex-start;
        color: #ffc7aa;
        text-align: center;
        font-family: "Work Sans";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 42px */
        position: relative;
        top: 0.8rem;
      }
    }

    h3 {
      color: #ffc7aa;
      text-align: center;
      font-family: "Work Sans";
      font-size: 72px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 79.2px */
      letter-spacing: -1.44px;
    }
    h4 {
      color: var(--Supporting-white, #fff);
      text-align: center;

      /* Title/Small/Bold */
      font-family: "Work Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 26px */
    }
    p {
      color: var(--Grey-100, #edeefa);
      text-align: center;

      /* Text/Large/Regular */
      font-family: "Work Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.16px;
    }
  }
`;
