import styled from "styled-components";

export const StyledHomepage = styled.main`
  header {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 62.7rem;
    width: 100%;
    z-index: 1;
    .flexLogoSearch {
      position: absolute;
      top: calc(9.6rem + var(--NavHeight));
      left: 50%;
      transform: translateX(-50%);

      .logo {
        margin-bottom: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--Scale-2, 16px);

        p {
          color: var(--Grey-200, #c5c8db);
          text-align: center;

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
      .flexSuggestions {
        margin-top: 6.4rem;
        display: flex;
        justify-content: center;
        gap: 10px;

        a {
          display: flex;
          height: 48px;
          padding: 12px 24px;
          align-items: center;
          gap: var(--Scale-2, 16px);
          border-radius: var(--Radius-md, 8px);
          background: rgba(170, 170, 240, 0.2);
          color: var(--Grey-100, #edeefa);
          text-align: center;
          text-decoration: none;
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
    }
  }
  .divider {
    min-height: calc(62.7rem - var(--NavHeight));
    width: 100%;
  }
  .lastOpportunities {
    .dontLost {
      text-align: center;
      margin-top: 9.6rem;
      font-size: 3.2rem;
      font-family: "Work Sans", sans-serif;
      font-weight: normal;
      margin-bottom: 3.2rem;
    }
    .slideContainer {
      width: 100%;
      position: relative;
      margin-bottom: 8rem;

      .um {
        left: 99.5%;
      }
      .dois {
        right: 99.5%;
      }

      .swiper-button-prev {
        top: 0 !important;
        margin-top: 0 !important;
        height: 100% !important;
        left: 0 !important;
        background-color: white !important;
        width: 5rem !important;
        z-index: 10 !important;
      }
      .swiper-button-next {
        top: 0 !important;
        margin-top: 0 !important;
        height: 100% !important;
        right: 0 !important;
        width: 5rem !important;
        background-color: white !important;
        z-index: 10 !important;
      }
      .swiper {
        padding: 0 5rem !important;
      }

      .tampaBug {
        position: absolute;
        height: 100%;
        z-index: 8;
        background-color: white;
        width: 3rem;
      }
    }
  }
  .aboutAlo {
    display: flex;
    padding: 96px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--Primary-50, #f2f3ff);
    .flexAbout {
      display: flex;
      width: 802px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      h1 {
        color: var(--Primary-400, #454ed1);

        /* Display/Medium/Regular */
        font-family: "Work Sans";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 76.8px */
      }
      p {
        color: var(--Grey-500, #606780);
        text-align: center;

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
  }
  .benefits {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 68.1rem;
    padding: 96px 0px;
    h1 {
      color: var(--Supporting-white, #fff);
      text-align: center;

      /* Display/Medium/Regular */
      font-family: "Work Sans";
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 76.8px */
      margin-bottom: 48px;
    }
    .flexAboutCards {
      width: 1056px;
      margin: 0 auto;
      display: flex;
      gap: var(--Scale-3, 24px);
    }
  }

  .tutorial {
    padding-top: 9.6rem;
    height: 976px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .flexSecond {
      display: flex;
      flex-direction: column;
      gap: 6.4rem;
    }
    h2 {
      color: #454ed1;
      text-align: center;
      font-family: "Work Sans";
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 64px */
      letter-spacing: -2.56px;
    }
    .flexTutorial {
      display: flex;
      margin: 0 auto 6.4rem auto;
      gap: 10rem;
    }
    .simple {
      text-align: center;
      color: #606780;
      text-align: center;
      font-family: "Work Sans";
      font-size: 2rem;
      font-style: italic;
      font-weight: 400;
      padding-bottom: 6.4rem;
      line-height: 140%; /* 28px */
    }
  }
  .statistcs {
    padding: 9.6rem 0;
    height: 39rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .flexStatistcs {
      margin: 0 auto;
      display: flex;
      width: 1416px;
      gap: var(--Scale-3, 24px);
    }
  }
  .oportunit {
    padding: 9.6rem 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 49.8rem;
    .boxCTA {
      width: 749px;
      margin-left: 360px;
      /* height: 306px; */
      display: flex;
      padding: 48px 80px;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      border-radius: var(--Radius-md, 8px);
      background: var(--Supporting-white, #fff);

      /* Drop shadow/xl */
      box-shadow: 0px 32px 32px 0px rgba(0, 0, 0, 0.25);
      .flexCTA {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Scale-3, 24px);
        h2 {
          color: var(--Primary-400, #454ed1);
          font-family: "Work Sans";
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.72px;
        }
        p {
          color: var(--Grey-500, #606780);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
      a {
        text-decoration: none;
        display: flex;
        height: 48px;
        padding: 12px var(--Scale-3, 24px);
        align-items: center;
        gap: var(--Scale-2, 16px);
        border-radius: var(--Radius-md, 8px);
        background: var(--Primary-500, #1b2cc1);
        color: var(--Supporting-white, #fff);
        text-align: center;
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
  }
`;
