import styled from "styled-components";

export const StyledSignUp = styled.main`
  background-color: var(--Primary-100, #e6e5ff);
  min-height: calc(100vh - var(--FooterHeight) - var(--NavHeight));
  .OldSignUp {
    display: flex;
    padding: 4.8rem 3.2rem;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    border-radius: 1.6rem;
    background: var(--Primary-25, #f2f3ff);
    max-width: 42.4rem;
    /* Drop shadow/sm */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    margin: 9.6rem auto;
    h2 {
      color: var(--Grey-700, #33394d);
      text-align: center;

      /* Title/Medium/Bold */
      font-family: "Work Sans";
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 24px */
      color: var(--Grey-700, #33394d);
      text-align: center;

      /* Title/Medium/Bold */
      font-family: "Work Sans";
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 100%; /* 24px */
      width: 100%;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      width: 100%;
      border-bottom: 1px solid var(--Grey-200, #c5c8db);
      padding: 0 0 4rem 0;

      .form-group {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        label {
          color: var(--Grey-700, #33394d);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
        .form-control {
          display: flex;
          height: 4.8rem;
          padding: 1rem 1.6rem;
          align-items: center;
          border-radius: 0.8rem;
          border: 1px solid var(--Grey-500, #a0a5bd);
          background: var(--Supporting-white, #fff);

          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
          &::placeholder {
            color: var(--Grey-300, #a0a5bd);
          }
          outline: none;
          /* &:focus-visible {
            border: none;
          } */
        }
      }
      button {
        margin-top: 1.6rem;
        display: flex;
        height: 5.6rem;
        padding: 2rem 3.2rem;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        border-color: transparent;
        border-radius: 0.6rem;
        background: var(--Primary-500, #1b2cc1);
        color: var(--Primary-050, #f0fdf4);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.8rem; /* 175% */
        cursor: pointer;
      }
    }

    .flexGoogleFacebook {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;

      button {
        cursor: pointer;
        width: 100%;
        display: flex;
        height: 4.8rem;
        padding: 1rem 1.6rem;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        border-radius: 0.8rem;
        border: 1px solid var(--Grey-500, #a0a5bd);
        background: var(--Supporting-white, #fff);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        .svg {
          justify-self: flex-end;
        }
        p {
          color: var(--Grey-700, #606780);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
    }

    .Register {
      margin-top: 0.8rem;
      color: var(--Grey-500, #606780);

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */

      a {
        color: var(--Primary-500, #1b2cc1);

        /* Text/Medium/Regular */
        font-family: "Work Sans";
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration: none;
      }
    }

    .error {
      color: var(--Error-500, #d41e21);
      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .svgScreen {
      width: 9.6rem;
      height: 9.6rem;
      align-self: flex-start;
    }
    .flexTittleText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Scale-4, 3.2rem);

      h1 {
        color: var(--Grey-700, #33394d);

        /* Title/Medium/Bold */
        font-family: "Work Sans";
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 24px */
      }
      p {
        color: var(--Grey-500, #606780);

        /* Text/Large/Regular */
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;

        span {
          color: var(--Grey-500, #606780);

          /* Text/Large/Regular */
          font-family: "Work Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: 0.16px;
        }
      }
    }
  }
  .newWarning {
    margin: var(--Scale-6, 4.8rem) auto 0;
    /* width: 38.2rem; */
    max-width: 31rem;
    text-align: center;
    color: var(--Grey-500, #606780);

    /* Text/Medium/Regular */
    font-family: "Work Sans";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 9.6rem;

    span {
      color: var(--Grey-500, #606780);
      text-align: center;

      /* Text/Medium/Bold */
      font-family: "Work Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    a {
      text-decoration: none;
      color: var(--Primary-500, #1b2cc1);

      /* Text/Medium/Regular */
      font-family: "Work Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .errorForm {
    border: var(--Scale-0, 1px) solid var(--Error-400, #de4c49) !important;
  }

  .flexTermos {
    height: 2.2rem;
    margin-top: 0.8rem;
    display: flex;
    gap: 0.8rem;
    color: var(--Grey-500, #606780);
    align-items: center;

    /* Text/Medium/Regular */
    font-family: "Work Sans";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    #termos {
      cursor: pointer;
    }
    a {
      color: var(--Primary-500, #1b2cc1);
      text-decoration: none;
      font-family: "Work Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      &:visited {
        color: var(--Primary-500, #1b2cc1);
        text-decoration: none;
      }
    }
  }

  .loader {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    display: block;
    position: relative;
    background: #fff;
    box-shadow: -12px 0 #fff, 12px 0 #fff;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
  }

  @keyframes shadowPulse {
    33% {
      background: #fff;
      box-shadow: -12px 0 var(--Primary-500, #1b2cc1), 12px 0 #fff;
    }
    66% {
      background: var(--Primary-500, #1b2cc1);
      box-shadow: -12px 0 #fff, 12px 0 #fff;
    }
    100% {
      background: #fff;
      box-shadow: -12px 0 #fff, 12px 0 var(--Primary-500, #1b2cc1);
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
