import { React, useState, useEffect } from "react";
import { StyledSecondSearchBar } from "../styles/SecondSearchBar";
import logoHeader from "../assets/logoHeader.svg";
import lupa from "../assets/lupa.svg";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import Skeleton from "../UI/Skeleton";
import SelectInput from "../UI/SelectInput";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function SecondSearchBar({
  page,
  setTermoPesquisado,
  termoPesquisado,
  setSearchReset,
  searchReset,
  width,
}) {
  const [userInput, setUserInput] = useState("");
  const [placeholder, setPlaceholder] = useState("Pesquise por um cargo");
  const [selected, setSelected] = useState(page);
  const [skeleton, setSkeleton] = useState(false);
  const navigate = useNavigate();
  let encodedSearchTerm = "";
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [inputIsLoaded, setInputIsLoaded] = useState(false);

  const location = useLocation();
  const pathname = decodeURIComponent(location.pathname);

  const handleImageLoad = () => {
    setImageIsLoaded(true);
  };

  if (pathname.includes("/cargos")) {
    setTermoPesquisado(pathname.split("/")[2]);
  } else if (pathname.includes("/concursos")) {
    setTermoPesquisado(pathname.split("/")[2]);
  }

  useEffect(() => {
    setTimeout(() => {
      setSkeleton(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (termoPesquisado) {
      setUserInput(termoPesquisado);
    }
  }, [termoPesquisado]);

  function capturingSearchTerm(term) {
    ReactGA.event({
      category: page === "Cargos" ? "Search in offices" : "Search in contests",
      action: "search_term",
      label: term,
    });
  }

  const searchFunction = (e) => {
    e.preventDefault();
    if (userInput === "") {
      setTimeout(() => {
        setPlaceholder("Por favor digite algo");
      }, 1000);
    } else {
      if (selected === "Cargos" || selected === "Homepage") {
        capturingSearchTerm(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        setTermoPesquisado(userInput);
        navigate(`/cargos/${encodedSearchTerm}`);
      } else {
        capturingSearchTerm(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        setTermoPesquisado(userInput);
        navigate(`/concursos/${encodedSearchTerm}`);
      }
    }

    setSearchReset(!searchReset);
  };

  function navegationPage(page) {
    navigate(page);
  }

  return (
    <StyledSecondSearchBar
      style={{ minWidth: width, maxWidth: width }}
      onSubmit={searchFunction}
      className="SecondSearchBar"
    >
      {page === "Homepage" ? null : !imageIsLoaded ? (
        skeleton ? (
          <Skeleton width={"20rem"} height={"5.5719rem"} />
        ) : null
      ) : null}

      {page === "Homepage" ? null : !inputIsLoaded ? (
        skeleton ? (
          <Skeleton width={"143.8690rem"} height={"5.3rem"} />
        ) : null
      ) : (
        <img
          className="logoSearch"
          src={logoHeader}
          alt="logo"
          onClick={() => navegationPage("/")}
          onLoad={handleImageLoad}
        />
      )}
      <div
        style={{
          display: inputIsLoaded ? "flex" : "none",
          minWidth: width,
        }}
        onLoad={() => setInputIsLoaded(true)}
        className="flexSearch"
      >
        <SelectInput
          defaultValue={page === "Homepage" ? "Cargos" : page}
          setSelected={setSelected}
        />

        <div className="pipe">|</div>
        <div className="flexInput">
          <input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            type="text"
            placeholder={placeholder}
          />
        </div>
        <div className="pipe">|</div>
        <button type="submit" className="lupa">
          <img src={lupa} alt="lupa" />
        </button>
      </div>
    </StyledSecondSearchBar>
  );
}

export default SecondSearchBar;
