import styled from "styled-components";

export const StyledTutorialBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  width: 404px;
  align-items: center;
  h5 {
    color: #7578e0;
    font-family: "Work Sans";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  img {
    width: 49rem;
  }
  h4 {
    color: #33394d;
    font-family: "Work Sans";
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -2.16px;
  }
  p {
    color: #606780;
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
`;
