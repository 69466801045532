import styled from "styled-components";

export const StyledAboutCards = styled.div`
  display: flex;
  height: 36.4rem;
  width: 33.6rem;
  padding: 4.8rem 3.2rem;
  flex-direction: column;
  gap: 2.4rem;
  border-radius: 1.6rem;
  background: #fff;
  box-shadow: 0px 0.4rem 0.4rem 0px rgba(0, 0, 0, 0.25);
  img {
    width: 8rem;
  }
  h4 {
    color: #33394d;
    font-family: "Work Sans";
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
  }
  p {
    color: #606780;
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
`;
